<template>
  <v-dialog v-model="deleteDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="red">mdi-delete</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2> {{ $t('are you sure you want delete') }} {{ tableRecord.entry.id }} ؟</h2>
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          depressed
          @click="deleteRecord"
          :loading="deleteBtnLoading"
          > {{ $t('delete') }} </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  data() {
    return {
      deleteBtnLoading: false,
      deleteDialog: false,
    };
  },
  props: {
    tableRecord: Object,
    serverRoute: String,
  },
  methods: {
    async deleteRecord() {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`${this.serverRoute}/${this.tableRecord.entry.id}`);
        this.$emit("record-deleted");
        this.deleteDialog = false;
        Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
  },
  created() {},
};
</script>

<style>
</style>