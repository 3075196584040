<template>
  <div>
    <v-dialog v-model="dialog" width="600"  open-on-click @input="openedHandler">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon class="ml-1" color="blue">mdi-pen</v-icon>
         
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>{{ $t('Edite Account') }}</h2>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-text-field v-model="accountData.name" :label="$t('account name')" outlined dense
            color="#757575"></v-text-field>
          <v-autocomplete :label="$t('parent account')" outlined dense v-model="accountData.parent" color="#757575"
            item-text="name" item-value="id" :loading="itemsLoading" :items="accountItems" return-object no-filter
            v-debounce="fetchAutoComplete" @input="changeSerial"></v-autocomplete>
          <div class="d-flex justify-center align-center">
            <v-text-field v-model="accountData.serial" :label="$t('account code')" outlined dense color="#757575"
              :disabled="!accountData.parent"></v-text-field>
            <p class="pb-2">-{{ getParentSerial }}</p>
          </div>
          <v-text-field v-model="accountData.tax_registration_number" :label="$t('Tax Registration Number')" outlined dense
            color="#757575"></v-text-field>
          <!-- <v-autocomplete
            label="الحساب الختامي"
            outlined
            dense
            color="#757575"
            :items="types"
            v-model="accountData.type"
            hide-details
          >
          </v-autocomplete> -->
          <v-checkbox :dark="$store.state.isDarkMode" :label="$t('The account is cash')" dense v-model="accountData.cash" :true-value="1"
            :false-value="0" color="#757575"></v-checkbox>
          <div class="d-flex justify-space-between align-center mb-5">
            <h4>{{ $t('Account specifications') }}</h4>
            <v-icon class="account-specifications__add-icon" @click="newSpecification = true">mdi-plus-thick</v-icon>
          </div>
          <v-fade-transition group mode="out-in">
            <v-row v-for="(specification, index) in accountSpecifications" :key="index">
              <v-col md="3" class="py-0">
                <v-text-field outlined dense v-model="specification.key"></v-text-field>
              </v-col>
              <v-col md="7" class="py-0">
                <v-text-field outlined dense v-model="specification.value"></v-text-field>
              </v-col>
              <v-col md="2">
                <v-icon color="red" @click="deleteAttribute(index)">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-fade-transition>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn :loading="submitAccountBtnLoading" depressed color="blue-grey" class="white--text"
            @click="addAccount">{{ $t('edit') }}</v-btn>
          <v-btn depressed color="primary" @click="dialog = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../../helpers/toast-notifications";
export default {
  props: {
    account: {
      type: Object,
    },
  },
  data() {
    return {
      accountData: {
        name: "",
        parent: {
          id: null,
          serial: null,
        },
        serial: null,
        // enum
        // checkbox
        cash: 0,
        editable: 1,
        hidden: 0,
        attributes: [],
        tax_registration_number: null,
      },
      parentSerial: null,
      // types: ["trading", "profits_and_losses", "balance"],
      newSpecification: false,
      dialog: false,
      itemsLoading: false,
      accountItems: [],
      accountSpecificationsArray: [],
      submitAccountBtnLoading: false,
    };
  },
  computed: {
    getParentSerial() {
      return (this.parentSerial = this.account.parent?.serial);
    },
    accountSpecifications() {
      if (this.newSpecification) {
        let accountSpecification = {
          key: "",
          value: "",
        };
        this.accountData.attributes.push(accountSpecification);
        this.newSpecification = false;
      }
      return this.accountData.attributes;
    },
  },

  methods: {
    changeSerial(){
     this.accountData.parent.serial = this.accountData.parent.serial; 
    },
    async openedHandler(v) {
      if (v) {
        this.accountData.name = this.account.name;
        this.accountData.parent.id = this.account.parent_id;
        this.accountData.cash = this.account.cash;
        this.accountData.editable = this.account.editable;
        this.accountData.hidden = this.account.hidden;
        this.accountData.serial = this.account.serial;
        this.accountData.attributes = this.account.attributes;
        this.accountData.tax_registration_number = this.account.tax_registration_number;
       
        this.accountData.parent.serial = this.account.parent.serial;
        const parentSerialLength = this.account.parent.serial.length;
        this.accountData.serial = this.accountData.serial.slice(
          parentSerialLength,
          this.accountData.serial.length
        );
        this.accountItems.push(this.account.parent);

        // if (this.account.sales_account) {
        //   this.accountData.sales_account_id = this.account.sales_account;
        //   this.salesItems.push(this.account.sales_account);
        // }
        // if (this.account.account_type) {
        //   this.accountData.account_type_id = this.account.account_type;
        //   this.account_typeItems.push(this.account.account_type);
        // }
        // if (this.account.sales_refund_account) {
        //   this.accountData.sales_refund_account_id =
        //     this.account.sales_refund_account;
        //   this.salesRefundItems.push(this.account.sales_refund_account);
        // }
        // if (this.account.purchases_account) {
        //   this.accountData.purchases_account_id =
        //     this.account.purchases_account;
        //   this.purchasesItems.push(this.account.purchases_account);
        // }
        // if (this.account.purchases_refund_account) {
        //   this.accountData.purchases_refund_account_id =
        //     this.account.purchases_refund_account;
        //   this.purchasesRefundItems.push(
        //     this.account.purchases_refund_account
        //   );
        // }
      }
    },
    async addAccount() {
      try {
        this.submitAccountBtnLoading = true;
        await axios.put(`/accounting/account/${this.account.id}`, {
          ...this.accountData,
          parent_id: this.accountData.parent.id,
          serial: this.accountData.parent.serial + this.accountData.serial,
        });
        this.dialog = false;
        this.$emit("reFetchTree");
        Notifications(
          "تمت التعديل بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.submitAccountBtnLoading = false;
      }
    },
    deleteAttribute(index) {
      this.accountData.attributes.splice(index, 1);
    },
    async fetchAutoComplete(value) {
      try {
        if (!value) return;
        this.itemsLoading = true;
        const searchResult = await axios.get(
          "/accounting/folder/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.accountItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.itemsLoading = false;
      }
    },
  },
};
</script>

<style>
.account-specifications__add-icon {
  color: #757575;
  border: 1px solid #757575;
  border-radius: 50%;
  padding: 3px;
}

.account-specifications__add-icon:hover {
  transform: scale(1.1);
}
</style>